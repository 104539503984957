import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  private currentStepSubject = new BehaviorSubject<string>('personal-info');
  currentStep$ = this.currentStepSubject.asObservable();

  private selectedPlanSubject = new BehaviorSubject<number | null>(null);
  private selectedPlanData = new BehaviorSubject<number | null>(null);
  setStep(step: string) {
    this.currentStepSubject.next(step);
  }

  getStep() {
    return this.currentStepSubject.getValue();
  }

  getSelectedPlanId() {
    return this.selectedPlanSubject.asObservable();
  }

  getPlanData(){
    return this.selectedPlanData.asObservable();
  }

  setSelectedPlanId(planId: number | null,data:any) {
    this.selectedPlanSubject.next(planId);
    this.selectedPlanData.next(data);
  }

  clearAll() {
    this.currentStepSubject.next('personal-info'); // Reset the step to an empty string or initial value
    this.selectedPlanSubject.next(null); // Reset the selected plan ID to null
    this.selectedPlanData.next(null); // Reset the selected plan data to null
}

}
